import React from "react"
import { ContentCard } from "../../layouts/ContentCard"

export interface AboutPageProps {}

const AboutPageComponent = (props: AboutPageProps) => {
	return (
		<ContentCard>
			<div className="flex flex-col">
				<h2 className="mb-5 text-4xl font-bold">About</h2>
				<p>Adundas makes websites.</p>

				<h3 className="mt-10 mb-3 text-3xl fond-bold">Projects</h3>
				<div>Stuff we have made:</div>
				<ul className="mx-auto mt-3 list-disc max-w-max">
					<li className="text-left">This site</li>
					<li>
						<a
							className="text-left hover:link "
							href="https://www.bodo-karateklubb.no/"
							target={"_blank"}
							rel="noreferrer"
						>
							Bodø Karateklubb
						</a>
					</li>
				</ul>
			</div>
		</ContentCard>
	)
}

export const AboutPage = React.memo(AboutPageComponent)
