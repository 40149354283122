import React from "react"
import { Route, Routes as ReactBrowserRoutes } from "react-router-dom"
import { BasicLayout } from "../layouts/BasicLayout/"
import { AboutPage } from "./Pages/AboutPage"
import { ContactPage } from "./Pages/ContactPage"
import { HomePage } from "./Pages/HomePage"
import { NotFoundPage } from "./Pages/NotFoundPage"

export interface RoutesProps {}

const RoutesComponent = (props: RoutesProps) => {
	return (
		<ReactBrowserRoutes>
			<Route path="/" element={<BasicLayout />}>
				<Route index element={<HomePage />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Route>
		</ReactBrowserRoutes>
	)
}

export const Routes = React.memo(RoutesComponent)
