import React from "react"
import { Link } from "react-router-dom"

export interface FooterProps {}

const FooterComponent = (props: FooterProps) => {
	return (
		<footer className="pt-2 footer bg-neutral text-neutral-content footer-center">
			<div className="flex items-center gap-4 mx-4 cursor-default">
				<div>
					<span className="block footer-title">ADUNDAS DEV</span>
				</div>
				<div className="flex items-center gap-4 mb-2 uppercase opacity-50 justify-evenly">
					<Link className="link link-hover" to="/" title="Link to home page">
						Home
					</Link>
					<Link className="link link-hover" to="about" title="Link to About page">
						About Adundas{" "}
					</Link>
					<Link className="link link-hover" to="contact" title="Link to Contact Information Page">
						Contact
					</Link>
				</div>
			</div>
		</footer>
	)
}

export const Footer = React.memo(FooterComponent)
