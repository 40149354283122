import React from "react"

export interface ContentCardProps {
	children: React.ReactNode
}

const ContentCardComponent = ({ children }: ContentCardProps) => {
	return (
		<div className="grid w-full h-full text-center place-content-center " id="hero-card">
			<div className="p-5">{children}</div>
		</div>
	)
}

export const ContentCard = React.memo(ContentCardComponent)
