import React, { useState } from "react"
import { ContentCard } from "../../layouts/ContentCard"

const email = "utvikler@adundas.dev"

export interface ContactPageProps {}

const ContactPageComponent = (props: ContactPageProps) => {
	const [showEmail, setShowEmail] = useState<boolean>(false)
	const [emailCopied, setEmailCopied] = useState<boolean>(false)

	const showEmailButtonHandler = () => {
		setShowEmail(true)
	}

	const hideEmailButtonHandler = () => {
		setShowEmail(false)
		setEmailCopied(false)
	}

	const copyEmailToClipBoardHandler = () => {
		setShowEmail(false)
		setEmailCopied(true)
		// Copy the text inside the text field
		navigator.clipboard.writeText(email)
	}

	return (
		<ContentCard>
			<div className="mb-4">
				<h2 className="mb-5 text-4xl font-bold">Contact</h2>
				<p>If you want to contant us, please use this email:</p>
			</div>
			{!showEmail && !emailCopied && (
				<div className="flex flex-col w-full gap-2">
					<button className="btn" onClick={showEmailButtonHandler}>
						Show Email
					</button>
					<p className="text-lg">{"< email is hidden >"}</p>
					<p>{` `}</p>
				</div>
			)}
			{showEmail && !emailCopied && (
				<div className="flex flex-col gap-2">
					<button className="btn" onClick={copyEmailToClipBoardHandler}>
						Copy email to clipboard
					</button>
					<p className="text-lg">{email}</p>
					<p>{` `}</p>
				</div>
			)}
			{emailCopied && (
				<div className="flex flex-col gap-2">
					<button className="btn" onClick={hideEmailButtonHandler}>
						Email has been copied
					</button>
					<p className="text-lg">{email}</p>
					<p>{` `}</p>
				</div>
			)}
		</ContentCard>
	)
}

export const ContactPage = React.memo(ContactPageComponent)
