import React from "react"
import { Link } from "react-router-dom"
import { ContentCard } from "../../layouts/ContentCard"

export interface HomePageProps {}

const HomePageComponent = (props: HomePageProps) => {
	return (
		<ContentCard>
			<h1 className="mb-5 text-5xl font-bold ">To the waves</h1>
			<p className="mb-5 text-lg font-bold tracking-widest uppercase">Web solutions</p>
			<Link to="about" className="btn btn-primary max-w-max">
				Learn more
			</Link>
		</ContentCard>
	)
}

export const HomePage = React.memo(HomePageComponent)
