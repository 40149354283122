import React from "react"
import { Outlet } from "react-router-dom"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { SideBar } from "../../Sidebar"

export interface BasicLayoutProps {}

const BasicLayoutComponent = (props: BasicLayoutProps) => {
	return (
		<>
			<div className="drawer drawer-end">
				<input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
				<div className="drawer-content">
					{
						//<!-- Page content here -->
					}
					<div className="flex flex-col justify-between h-screen" id="basic-layout-wrapper">
						<section className="p-4 px-4" id="header-section">
							<Header />
						</section>
						<section className="flex-1 w-full px-6" id="main-section">
							<Outlet />
						</section>
						<section>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
								<path
									fill="#6418e7"
									fillOpacity={"1"}
									d="M0,256L30,256C60,256,120,256,180,218.7C240,181,300,107,360,64C420,21,480,11,540,26.7C600,43,660,85,720,117.3C780,149,840,171,900,186.7C960,203,1020,213,1080,202.7C1140,192,1200,160,1260,149.3C1320,139,1380,149,1410,154.7L1440,160L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
								></path>
							</svg>
						</section>
						<section id="footer-section">
							<Footer />
						</section>
					</div>
				</div>
				<SideBar />
			</div>
		</>
	)
}

export const BasicLayout = React.memo(BasicLayoutComponent)
