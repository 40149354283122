import React from "react"
import { useNavigate } from "react-router-dom"

export interface SideBarProps {}

const SideBarComponent = (props: SideBarProps) => {
	const navigate = useNavigate()
	return (
		<div className="drawer-side">
			<label htmlFor="my-drawer-4" className="drawer-overlay"></label>
			<ul className="p-4 overflow-y-auto menu w-60 bg-base-100 text-base-content">
				{
					// <!-- Sidebar content here -->
				}
				<li>
					<label htmlFor="my-drawer-4" onClick={() => navigate("/")} className="drawer-button" title="Link to home">
						Home
					</label>
				</li>
				<li>
					<label
						htmlFor="my-drawer-4"
						onClick={() => navigate("about")}
						className="drawer-button"
						title="Link to Contact Information Page"
					>
						About Adundas
					</label>
				</li>
				<li>
					<label
						htmlFor="my-drawer-4"
						onClick={() => navigate("contact")}
						className="drawer-button"
						title="Link to Contact Information Page"
					>
						Contact
					</label>
				</li>
			</ul>
		</div>
	)
}

export const SideBar = React.memo(SideBarComponent)
