import React from "react"

export interface NotFoundPageProps {}

const NotFoundPageComponent = (props: NotFoundPageProps) => {
	return (
		<div>
			<h2>Oppsie</h2>
			<p>The requests page was not found!</p>
		</div>
	)
}

export const NotFoundPage = React.memo(NotFoundPageComponent)
